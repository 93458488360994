@font-face {
    font-family: 'Whyte';
    src: url('../../font/Whyte-Medium.ttf');
    

}

@font-face {
    font-family: 'Whyte2';
    src: url('../../font/Whyte-Bold.ttf');

}

.first-div{
    background-image: url(../../img/grid.png);
    height: fit-content;
}


.text-div{
    font-family: whyte2;
    padding-top:15px;
}

.ideatech{
    font-family: whyte;
    width: fit-content;
    margin: 0 auto;
    padding-bottom: 20px ;
}

#ideate{
   position: relative;
   z-index: 1;
   
}


#ideate::before{
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateX(15px);
    width: 100%;
    height: 40px;
    opacity: 0.85;
    background: rgb(68, 114, 241,0.75);
    z-index: -1;
    animation: animehighlight 2s;
}



@keyframes animehighlight {
    0%{
        width: 0%;
    }
    100%{
        width: 100%;
    }
}

#technicate{
        
    position: relative;
    z-index: 1;
     
}

#technicate::before{
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40px;
    background:rgb(255, 199, 0, 0.85);
    opacity: 0.85;
    z-index: -1;
    animation: animehighlight 2s;
}


.button{
    border-radius: 12px;
    color: white;
    font-family: whyte;
}


.register-div{
    display: flex;
    align-items: center;
    flex-direction: row;
    width: fit-content;
    margin: 0 auto;
    padding-top: 50px;
    position: relative;
}

.first-animation{
    top: -370px;
    left: -480px;
    position: absolute;
}




/*************************************************** first animation **************************************************/

 @-webkit-keyframes animate-svg-stroke-1 {
    0% {
      stroke-dashoffset: 1055.021240234375px;
      stroke-dasharray: 1055.021240234375px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 1055.021240234375px;
    }
  }
  
  @keyframes animate-svg-stroke-1 {
    0% {
      stroke-dashoffset: 1055.021240234375px;
      stroke-dasharray: 1055.021240234375px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 1055.021240234375px;
    }
  }
  
  
  
  .svg-elem-1 {
    -webkit-animation: animate-svg-stroke-1 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both ;
            animation: animate-svg-stroke-1 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both ;
  }
  
  
.timebox {
    /* width: 141.38px;
    height: 144.1px; */
    background-image: url(../../img/toppng\ 1.png);
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.time-div{
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* width: 100%; */
    font-family: whyte2;
    /* font-size: 30px; */
}



@media only screen and (max-width: 600px) {
    #ideate::before{
        height: 15px;
        transform: translateX(5px);
    }

    #technicate::before{
        height: 15px;
    }
  
}