
/* div.container > h1::before {
    content: url('../../assets/images/Untitled_Artwork\ 4\ 1\ \(Traced\).svg');
    position: absolute;
    top: 0;
    left: 0;
}

div.container > h1::after {
    content: url('../../assets/images/Untitled_Artwork\ 4\ 1\ \(Traced\)-1.svg');
    position: absolute;
    top: 0;
    right: 0;
} */

/* div.image::before {
    content: url('../../assets/images/yellow\ and\ black.svg');
    position: absolute;
    top: 259px;
    left: 385px;
} */




@keyframes animehighlight {
    0%{
        width: 0%;
    }
    100%{
        width: 100%;
    }
}

.technicate{
        
    position: relative;
    z-index: 1;
     
}

.technicate::before{
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40px;
    background:rgb(255, 199, 0, 0.85);
    opacity: 0.85;
    z-index: -1;
    animation: animehighlight 2s;
}




@keyframes animehighlight {
    0%{
        width: 0%;
    }
    100%{
        width: 100%;
    }
}

.special{
        
    position: relative;
    z-index: 1;
     
}

.special::before{
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40px;
    background:rgba(255, 94, 0, 0.85);
    opacity: 0.85;
    z-index: -1;
    animation: animehighlight 2s;
}
