
.center{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}
.center2{
 margin-bottom: 0px;
display: block;
margin-left: auto;
margin-right: auto;
margin-bottom: auto;
width: 80%;
}
.container{
 text-align: center;
 position: relative;
padding-bottom: 20px;
padding-top: 20px;
}
.font-whyte {
    font-family: 'Whyte';
    font-style: normal;
}


.animation-speakers:hover{
  animation: effect 0.5s ease-in-out;
}

@keyframes effect {
  /* Cool Effect and More Creative */
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }

}