@font-face {
  font-family: 'Whyte';
  src: url('../../font/Whyte-Medium.ttf');
  

}

@font-face {
  font-family: 'Whyte2';
  src: url('../../font/Whyte-Bold.ttf');
  

}

footer{
  background: url(../../img/footer.svg) no-repeat center;
}
.center{
  display: block;
margin-left: auto;
margin-right: auto;
}
.container{
position: relative;
padding-left: 20px;

}
.centered {
 
position: absolute;
  top: 20%;
  left: 50%;

}

.fb{
  display: inline-block;
  margin-left: auto ;
  /* margin-right: 20px ;
  margin-top: 60px; */
  flex-grow: 0.01;
  /* height: 30px;
  width: 30px; */
  transition: 0.5s;
}
.fb:hover{
 
  height: 41px;
  width: 41px;
  transition: 0.5s;
}
.ig{
  display: inline-block;
  transition: 0.5s;
}
.ig:hover{
 
  height: 41px;
  width: 41px;
  transition: 0.5s;
}
.in{
  display: inline-block;
  flex-grow: 0.01;
  transition: 0.5s;
}
.in:hover{
 
  height: 41px;
  width: 41px;
  transition: 0.5s;
}

.text{
  display: inline-block;
  color: #776F6F;
  /* flex-grow: 10; */
  /* height: 400px;
  width: 400px; */
  /* font-family: 'Inter'; */
font-style: normal;

}


.bord{
  position: absolute;
  width: 101%;
  height: 40px;
  top:-42px;
  left:0 ;
  background:transparent url('../../img/wiw2.png');
  background-size: 800px 40px;
  border: 0px solid transparent;
  }

.bord#bord1{
  z-index:1000;
  opacity: 1;
  bottom : 0px;
  animation: animateWave 30s linear infinite;

}

@keyframes animateWave {
  0%{
     background-position-x: 2000px;
  }
  100%{
    background-position-x: 0px;
  }
}